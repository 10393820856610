<template>
  <div>
    <div class="font-medium text-xl mb-8">Model Day</div>
    <div class="border-b border-gray-200 pb-2">
      <span class="text-teal pb-2 border-b-2 border-teal">{{
        currentShift
      }}</span>
    </div>
    <div class="bg-white mt-8">
      <div class="text-xl font-semibold tracking-wide ml-5 py-5">
        {{ practiceName }}
      </div>
      <div class="border border-gray-100">
        <div class="flex flex-col px-6 mt-6">
          <div
            v-for="(item, questionId) in submittedActivity"
            :key="questionId"
          >
            <div class="flex justify-between">
              <div class="flex align-middle items-center">
                <div class="arrow-active"></div>
                <div class="font-extralight text-gray-600">
                  {{ item.activity.activity }}
                </div>
              </div>
              <div class="mr-4">
                <div
                  v-if="item.activity.type === 'string'"
                  class="grid grid-cols-2 gap-8 mb-4"
                >
                  <div
                    v-for="(option, optionIndex) in options"
                    :key="optionIndex"
                    @click="activeOption(questionId, optionIndex)"
                    class="cursor-not-allowed"
                  >
                    <button
                      class="w-full border border-gray-200 rounded p-3 text-left"
                      :class="{
                        [`${fieldColor()} text-white`]: isActive(
                          questionId,
                          optionIndex
                        ),
                      }"
                      disabled
                    >
                      {{ option }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="item.activity.type === 'numeric'"
              class="grid grid-cols-1 w-full border mt-5 mb-8 border-gray-200 rounded cursor-not-allowed"
            >
              <input
                v-model="numberInputs[questionId]"
                type="number"
                class="rounded p-4"
                disabled
              />
            </div>

            <div v-show="selectedOption[questionId]">
              <input
                class="border border-gray-200 w-full rounded p-4 mb-8 transition duration-150 ease-in cursor-not-allowed"
                type="text"
                placeholder="Reason here*"
                v-model="reason[questionId]"
                disabled
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

/**----------------------------------------------------------------- */

const route = useRoute();
const store = useStore();
const practiceId = route.params.practiceId;
const instanceId = route.params.instanceId;
const shiftId = route.params.shiftId;
const practiceName = localStorage.getItem("practiceName");
const options = ref(["Yes", "No"]);
const activeItems = ref([]);
const selectedOption = ref([]);
const numberInputs = ref({});
const reason = ref([]);

/**
 * -------------------------------------------------------------------
 * computed property to set the shift according to selected submission
 * -------------------------------------------------------------------
 */

const currentShift = computed(() => {
  switch (parseInt(shiftId)) {
    case 1:
      return "Morning 8:00 am";
    case 2:
      return "Mid-Day 11:00 am";
    case 3:
      return "Evening 4:00 pm";
    default:
      return "Unavailable";
  }
});

/**
 * --------------------------------------------
 * map the submitted activities to the template
 * --------------------------------------------
 */

const submittedActivity = computed(() => {
  if (submission.value && submission.value.submissions) {
    submission.value.submissions.forEach(() => {
      activeItems.value.push(ref(null));
      selectedOption.value.push(false);
    });
    submission.value.submissions.map((activity, index) => {
      const optionId = activity.answer === "no" ? 1 : 0;
      activeItems.value[index].value = optionId;
      if (options.value[optionId] === "No") {
        selectedOption.value[index] = true;
        reason.value[index] = activity.reason;
      } else {
        selectedOption.value[index] = false;
      }
      if (activity.activity.type === "numeric") {
        numberInputs.value[index] = activity.answer;
      }
    });
    return submission.value.submissions;
  }
  return null;
});

const isActive = (questionIndex, optionIndex) => {
  const activeItem = activeItems.value[questionIndex];
  return activeItem && activeItem.value === optionIndex;
};

const fieldColor = () => {
  return "bg-teal cursor-not-allowed";
};

/**
 * ------------------
 * Getters
 * ------------------
 */

const submission = computed(() => {
  return store.getters["hqModelDay/getSingleInstance"];
});

/**
 * ------------------
 * action call
 * ------------------
 */

const fetchSingleSubmission = async () => {
  await store.dispatch("hqModelDay/fetchSingleInstance", {
    instanceId: instanceId,
    shiftId: shiftId,
    practiceId: practiceId,
  });
};

onMounted(fetchSingleSubmission());
</script>

<style scoped>
.arrow-active {
  width: 0;
  height: 0;
  padding-bottom: 3px;
  margin-right: 5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--Submit, rgba(0, 164, 153, 1));
  transform: rotate(90deg);
  display: inline-block;
}
</style>
